import React, { useState, useEffect } from "react"
import "../../flourishing-easy.scss"
import { Link } from "gatsby"
import Slider from "react-slick"
import SimplePackage from "../../../../images/home/flourishEasy/simplePackage.svg"
import SimplePackagePng from "../../../../images/home/flourishEasy/simplePackage.png"
import Deficit from "../../../../images/home/flourishEasy/deficit.svg"
import Faster from "../../../../images/home/flourishEasy/faster.svg"
import FasterMob from "../../../../images/home/flourishEasy/faster-mob.svg"
import Metric from "../../../../images/home/flourishEasy/metric.svg"
import BusinessArrow from "../../../../images/home/businessOutcomes/business2.mp4"
import { dataDesktop, dataMobile, getSettings } from "../../help"
import ArrowRight from "../../../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../../../images/learnmore/arrowLeft-light.svg"
import { isSafari } from "react-device-detect"

const FlourishCard = ({ card, index, isMobile }) => {
  const [isSafariBrow, setIsSafari] = useState(false)
  useEffect(() => {
    setIsSafari(isSafari)
  }, [isSafari])
  let i = isMobile ? index + 1 : index
  let simplePackage = i == 0
  let metric = i == 1
  let faster = i == 2
  let speed = i == 3
  let deficit = i == 4

  const AssetsMap = {
    SimplePackage: isSafariBrow ? SimplePackagePng : SimplePackage,
    Deficit: Deficit,
    Faster: isMobile ? FasterMob : Faster,
    Metric: Metric,
    BusinessArrow: BusinessArrow,
  }

  return (
    <div className="flourishEasyCard">
      {simplePackage && (
        <>
          <h3 className="flourishHeading">{card?.heading}</h3>
          <p
            className="flourishDesc"
            dangerouslySetInnerHTML={{ __html: card?.subHeading }}
          />
        </>
      )}
      <div
        className={`flourishEasyCardFlex ${
          simplePackage ? "simplePackageWrap" : ""
        }`}
      >
        <div
          className={`flourishEasyCardContent ${
            simplePackage ? "simplePackage" : ""
          } ${faster ? "faster" : ""} ${speed ? "speed" : ""} ${
            metric ? "metric" : ""
          }  ${deficit ? "deficit" : ""}`}
        >
          <h3>{card?.heading}</h3>
          <div
            className="flourishDescInside"
            dangerouslySetInnerHTML={{
              __html: `<div>${card?.desc}</div>`,
            }}
          />
        </div>
        <div
          className={`flourishEasyCardImg ${speed ? "videoWrap" : ""} ${
            simplePackage ? "simplePackage" : ""
          } ${metric ? "metric" : ""}  ${deficit ? "deficit" : ""}`}
        >
          {!speed ? (
            <img src={AssetsMap[card.image]} />
          ) : (
            <>
              <video
                autoPlay
                muted
                playsInline
                loop
                loading="lazy"
                className="delayVideo"
              >
                <source src={AssetsMap[card.image]} type="video/mp4" />
                Sorry, your browser doesn't support videos.
              </video>
            </>
          )}
        </div>
      </div>

      {card.cta.content && (
        <Link
          to={card.cta.link}
          className={`buttonCentered ${
            simplePackage ? "simplePackageCta" : ""
          }`}
        >
          {card.cta.content}
        </Link>
      )}
    </div>
  )
}

export const FlourishDesktop = () => {
  let [pageNo, setPageNo] = useState(0)

  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />,
    setPageNo
  )

  return (
    <div
      className={`flourishEasyWrapper ${
        pageNo === 0 || pageNo % 2 === 0 ? "darkBg" : "lightBg"
      }`}
    >
      <div className="flourishEasyCarousel desktopFlourishCarousel">
        <Slider {...settings}>
          {dataDesktop &&
            dataDesktop?.map((card, index) => {
              return <FlourishCard card={card} index={index} isMobile={false} />
            })}
        </Slider>
      </div>
      <div className="flourishEasyCarousel mobileFlourishCarousel">
        <Slider {...settings}>
          {dataMobile &&
            dataMobile?.map((card, index) => {
              return <FlourishCard card={card} index={index} isMobile={true} />
            })}
        </Slider>
      </div>
    </div>
  )
}
