import React from "react"
import "./trust-companies.scss"
import { Link } from "gatsby"
import Slider from "react-slick"
import { ClientFeedback } from "./components/clientFeedback/clientFeedback"
import Doug from "../../images/learnmore/doug2.png"
import Emma from "../../images/learnmore/emma.png"
import Jenefir from "../../images/learnmore/jennifer.png"
import Bob from "../../images/learnmore/bob.png"
import Nicola from "../../images/home/overcoming/advisor.jpg"
import ArrowRight from "../../images/learnmore/arrowRight.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft.svg"

export const TrustCompanies = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src={ArrowRight} />,
    prevArrow: <img src={ArrowLeft} />,
  }
  let data = [
    {
      name: "Doug Lowrie",
      feedback:
        "“I want to see my personal SHAPE scores compared to my teams, where I’m contributing, and where I need to focus more energy.”",
      designation: "Global Workplace Director, Sodexo",
      clientImg: Doug,
    },
    {
      name: "Nicola Thompson",
      feedback:
        "“SHAPE is different…SHAPE helps you understand employee experience, and what they need to do to flourish.”",
      designation: "ex-CEO, MADE.com",
      clientImg: Nicola,
    },
    {
      name: "Jennifer Wakefield",
      feedback:
        "“SHAPE insights provided a starting point for meaningful conversations.”",
      designation: "Marketing Manager, EFG Furniture",
      clientImg: Jenefir,
    },
    {
      name: "Emma Wilson",
      feedback:
        "“SHAPE has been a game changer. It really helps in any business case. It's really the full package. It's fantastic.”",
      designation: "Social Impact Director, Pareto FM",
      clientImg: Emma,
    },
    {
      name: "Bob Stamegna",
      feedback:
        "“What you really want is for your staff members to be flourishing, SHAPE really provided the data that helped us get to that.”",
      designation: "Head of HR & Well-being, CN Foods",
      clientImg: Bob,
    },
  ]
  return (
    <div className="trustCompaniesWrapper">
      <h1>Trusted by Companies Everywhere</h1>
      <div className="trustCompaniesCarousel">
        <Slider {...settings}>
          {data?.map((client) => (
            <ClientFeedback {...client} />
          ))}
        </Slider>
      </div>

      <Link className="trustCompaniesCta" to="/learnmore">
        Hear Our Clients
      </Link>
    </div>
  )
}
