import React from "react"
import "./impact.scss"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"
import { carouselData, carouselDataMob, getSettings } from "./helper"
import Slider from "react-slick"

export const Impact = () => {
  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />
  )

  return (
    <div className="impactWrapper">
      <div className="impactContentWrapper">
        <h1>We're Making an Impact</h1>
        <hr />
        <div className="impactFactsWrapper impactFactsDesktop">
          {carouselData && (
            <Slider {...settings}>
              {carouselData?.map((card, index) => (
                <div
                  className={`impactFactsItem ${index == 2 ? "lastFact" : ""}`}
                  key={`${card.desc}-${index}`}
                >
                  <h3
                    className={`${card?.flourish ? "impactFactPrimary" : ""}`}
                  >
                    {card?.heading}
                  </h3>
                  <p>{card?.desc}</p>
                </div>
              ))}
            </Slider>
          )}
        </div>
        <div className="impactFactsWrapper impactFactsMobile">
          {carouselDataMob && (
            <Slider {...settings}>
              {carouselDataMob?.map((card, index) => (
                <div
                  className={`impactFactsItem ${index == 2 ? "lastFact" : ""}`}
                  key={`${card.desc}-${index}`}
                >
                  <h3
                    className={`${card?.flourish ? "impactFactPrimary" : ""}`}
                  >
                    {card?.heading}
                  </h3>
                  <p>{card?.desc}</p>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}
