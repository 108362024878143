let dataMobile = [
  {
    heading: "Flourishing is a Super-Metric",
    desc: "<b>Flourishing and productivity go up and down together.</b>",
    cta: {
      content: "",
      link: "",
    },
    image: "Metric",
  },
  {
    heading: "Flourish Faster",
    desc: "Personalised,<br/> science-backed guidance on the actions that accelerate everyone's chances of <strong>flourishing.</strong>",
    cta: {
      content: "",
      link: "",
    },
    image: "Faster",
  },
  {
    heading: "Faster Means Coordinated Speed",
    desc: "Get aligned on important business goals so individual actions support <b>collective progress.</b>",
    cta: {
      content: "Solve Business Challenges",
      link: "/learnmore/#address-business-challenges",
    },
    image: "BusinessArrow",
  },
  {
    heading: "Address Your Flourishing Deficit",
    desc: "SHAPE helps you measure where and how much everyone is struggling, striving, or flourishing.<br/><strong><br/>Precision level insights into areas that matter most.</strong>",
    cta: {
      content: "Explore the Explorers",
      link: "/science/#explorer-view",
    },
    image: "Deficit",
  },
]

let dataDesktop = [
  {
    heading: "Flourishing Made Easy",
    subHeading:
      "<strong>Everything you need to accelerate to flourishing in one simple package.</strong>",
    desc: "<br/><ul><li>Up to 10x faster flourishing through personalised guidance</li><li>Year-round nudges to help build a flourishing mindset</li><li>Exclusive trends bulletins for executives to address the flourishing deficit</li></ul>",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "SimplePackage",
    desktopOnly: true,
  },
  {
    heading: "Flourishing is a Super-Metric",
    desc: "<b>Flourishing and productivity go up and down together.</b>",
    cta: {
      content: "",
      link: "",
    },
    image: "Metric",
  },
  {
    heading: "Flourish Faster",
    desc: "Personalised,<br/> science-backed guidance on the actions that accelerate everyone's chances of <strong>flourishing.</strong>",
    cta: {
      content: "",
      link: "",
    },
    image: "Faster",
  },
  {
    heading: "Faster Means Coordinated Speed",
    desc: "Get aligned on important business goals so individual actions support <b>collective progress.</b>",
    cta: {
      content: "Solve Business Challenges",
      link: "/learnmore/#address-business-challenges",
    },
    image: "BusinessArrow",
  },
  {
    heading: "Address Your Flourishing Deficit",
    desc: "SHAPE helps you measure where and how much everyone is struggling, striving, or flourishing.<br/><strong><br/>Precision level insights into areas that matter most.</strong>",
    cta: {
      content: "Explore the Explorers",
      link: "/science/#explorer-view",
    },
    image: "Deficit",
  },
]
let getStartedData = [
  {
    heading:
      "<b>Everything you need to accelerate to flourishing in one simple package.</b>",
    desc: "Available on all platforms <br/><br/><br/>",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "SimplePackage",
  },
  {
    heading: "<b>Flourishing Made Easy</b>",
    desc: "Up to 10x faster flourishing through personalised guidance",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "Mob3",
  },
  {
    heading: "<b>Flourishing Made Easy</b>",
    desc: "Year-round nudges to help build a flourishing mindset",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "Mob2",
  },
  {
    heading: "<b>Flourishing Made Easy</b>",
    desc: "Exclusive trends bulletins for executives to address the flourishing deficit",
    cta: {
      content: "Pricing",
      link: "/pricing",
    },
    image: "Mob1",
  },
]

const getSettings = (right, left, setPageNo) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: right,
    prevArrow: left,
    beforeChange: (oldIndex, newIndex) => {
      setTimeout(() => {
        setPageNo && setPageNo(newIndex)
      }, 200)
    },
  }
  return settings
}
export { dataMobile, dataDesktop, getSettings, getStartedData }
