import React from "react"
import "../../trust-companies.scss"

export const ClientFeedback = (props) => {
  let { clientImg, feedback, name, designation } = props
  return (
    <div className="clientFeedbackWrapper">
      <div
        className={`clientImg ${name.includes("Jennifer") ? "jen" : ""} ${
          name.includes("Emma") ? "emma" : ""
        } ${name.includes("Bob") ? "bob" : ""}`}
      >
        <img src={clientImg} alt="Client" lazy="true" />
      </div>
      <div className="clientFeedbackContent">
        <p className="clientFeedback">{feedback}</p>
        <div>
          <h4 className="clientName">{name}</h4>
          <p className="clientDesignation">{designation}</p>
        </div>
      </div>
    </div>
  )
}
